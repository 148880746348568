import { format } from "date-fns";

export const formatDate = (date?: string | Date) => {
  if (!date) return "N/A";

  const parsedDate = typeof date === "string" ? new Date(date) : date;

  if (isNaN(parsedDate.getTime()) || parsedDate.getFullYear() === 1) {
    return "N/A";
  }

  return format(parsedDate, "dd/MM/yyyy HH:mm:ss");
};

export const formatMonth = (month: number) => {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  return months[month - 1];
};

export const formatBirthdate = (date?: string | Date): string => {
  if (!date) return "";

  const parsedDate = typeof date === "string" ? new Date(date) : date;

  if (isNaN(parsedDate.getTime()) || parsedDate.getFullYear() === 1) {
    return "";
  }
  
  return format(date, "dd/MM/yyyy");
};
