import {
  CourseTemplate,
  File,
  InvoicesDate,
  Mail,
  Session,
  StatsHandler,
  Training,
  TrainingTemplate,
  ZekayConfig,
} from "./Zekay.typed";
import { ZekayModel } from "./ZekayModel";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class ZekayAdapter {
  zekayModel: ZekayModel;

  constructor(zekayModel: ZekayModel) {
    this.zekayModel = zekayModel;
  }

  async refreshZuurit() {
    return await this.zekayModel.refreshZuurit();
  }

  async getCustomers() {
    return await this.zekayModel.getCustomers();
  }

  async getCustomer(id: string) {
    await sleep(500);
    return await this.zekayModel.getCustomer(id);
  }

  async getDoneTrainings() {
    return await this.zekayModel.getDoneTrainings();
  }

  async updateTraining(t: Training) {
    return await this.zekayModel.updateTraining(t);
  }

  async getTrainingsByCreditId(id: string) {
    return await this.zekayModel.getTrainingsByCreditId(id);
  }

  async getTrainingsByStatusCreditId(id: string, status: number) {
    return await this.zekayModel.getTrainingsByStatusCreditId(id, status);
  }

  async getTrainingByUserCreditId(creditId: number, userId: number) {
    return await this.zekayModel.getTrainingByUserCreditId(creditId, userId);
  }

  async getTrainingsTemplates() {
    return await this.zekayModel.getTrainingsTemplates();
  }

  async updateTrainingTemplate(tt: TrainingTemplate) {
    return await this.zekayModel.updateTrainingTemplate(tt);
  }

  async createTraining(training: Training) {
    return await this.zekayModel.createTraining(training);
  }

  async deleteTraining(training: Training) {
    return await this.zekayModel.deleteTraining(training);
  }

  async validateUserQualification(t: Training) {
    await this.zekayModel.validateUserQualification(t);
  }

  async updateFile(file: File) {
    return await this.zekayModel.updateFile(file);
  }

  async updateTrainingFile(formData: FormData) {
    return await this.zekayModel.updateTrainingFile(formData);
  }

  async deleteTrainingFile(file: File) {
    return await this.zekayModel.deleteTrainingFile(file);
  }

  async sendMail(mail: Mail) {
    return await this.zekayModel.sendMail(mail);
  }

  async getProductsCsv() {
    return await this.zekayModel.getProductsCsv();
  }

  async getInvoicesCsv(period: InvoicesDate) {
    return await this.zekayModel.getInvoicesCsv(period);
  }

  async getExcursionsStatsByYear(date: StatsHandler) {
    return await this.zekayModel.getExcursionsStatsByYear(date);
  }

  async getExcursionsStatsByMonth(date: StatsHandler) {
    return await this.zekayModel.getExcursionsStatsByMonth(date);
  }

  async getTrainingStatsByYear(date: StatsHandler) {
    return await this.zekayModel.getTrainingStatsByYear(date);
  }

  async getStatDates() {
    return await this.zekayModel.getStatDates();
  }

  async getLogsByTable(table: string) {
    return await this.zekayModel.getLogsByTable(table);
  }

  async getActionsLogs() {
    return await this.zekayModel.getActionsLogs();
  }

  async getWorkerLogs() {
    return await this.zekayModel.getWorkerLogs();
  }

  async getWorkerLogsByUserId(id: string) {
    return await this.zekayModel.getWorkerLogsByUserId(id);
  }

  async getWorkerLogsByStatus(status: string) {
    return await this.zekayModel.getWorkerLogsByStatus(status);
  }

  async getCourseTemplate(id: number) {
    return await this.zekayModel.getCourseTemplate(id);
  }

  async createCourseTemplate(ct: CourseTemplate) {
    return await this.zekayModel.createCourseTemplate(ct);
  }

  async updateCourseTemplate(ct: CourseTemplate) {
    return await this.zekayModel.updateCourseTemplate(ct);
  }

  async createSession(session: Session) {
    return await this.zekayModel.createSession(session);
  }

  async updateSession(session: Session) {
    return await this.zekayModel.updateSession(session);
  }

  async generateSessionPDF(creditId: number, customerId: number) {
    return await this.zekayModel.generateSessionPDF(creditId, customerId);
  }

  async getConfig(id: number) {
    return await this.zekayModel.getConfig(id);
  }

  async updateConfig(cfg: ZekayConfig) {
    return await this.zekayModel.updateConfig(cfg);
  }

  async getSystemInfos() {
    return await this.zekayModel.getSystemInfos();
  }

  async getGoogleLabels() {
    return await this.zekayModel.getGoogleLabels();
  }

  async getQualificationsList() {
    return await this.zekayModel.getQualificationsList();
  }

  async getNewsletterMapping() {
    return await this.zekayModel.getNewsletterMapping();
  }

  async updateNewsletterMapping(mapping: { [key: string]: string[] }) {
    return await this.zekayModel.updateNewsletterMapping(mapping);
  }
}
