import React, { Dispatch, SetStateAction } from "react";
import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import { SClose, SMissing } from "../../../styles/StyledMUI";
import { Box, IconButton, Typography } from "@mui/joy";
import PhotoComponent from "./display/PhotoComponent";
import { AuthContext } from "../../../contexts/AuthContext";
import { formatBirthdate } from "../../../utils/dateTime";

type ProfileFormProps = {
  customer: ZekayCustomer | undefined;
  setProfileFormOpen: Dispatch<SetStateAction<boolean>>;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
};

export default function ProfileForm({
  customer,
  setProfileFormOpen,
  setCustomerUpdated,
}: ProfileFormProps) {
  const { isAdmin } = React.useContext(AuthContext);

  const styles = {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 1300,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    },
    content: {
      position: "relative",
      width: "100%",
      maxWidth: "800px",
      height: "auto",
      maxHeight: "90vh",
      overflowY: "auto",
      borderRadius: "10px",
      padding: 3,
      backgroundColor: "#171717",
    },
    Typography: {
      color: "white",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
    header: {
      color: "#FCCB06",
      fontWeight: "bold",
      marginBottom: 2,
      fontSize: "clamp(1rem, 2.5vw, 1.5rem)",
    },
    missing: {
      display: "flex",
      alignItems: "center",
      color: "white",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <IconButton
          onClick={() => setProfileFormOpen(false)}
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
          }}
        >
          <SClose sx={{ color: "white" }} />
        </IconButton>

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <Box flex="1" pr={{ xs: 0, md: 2 }} mb={{ xs: 3, md: 0 }}>
            <Typography sx={styles.header}>
              {customer?.FirstName + " " + customer?.LastName}
            </Typography>
            {customer?.MailAddress ? (
              <Typography sx={styles.Typography}>
                {customer.MailAddress}
              </Typography>
            ) : (
              <Box sx={styles.missing}>
                <SMissing />
                <Typography sx={styles.Typography}>
                  Email non renseigné
                </Typography>
              </Box>
            )}
            {customer?.Street || customer?.PostalCode || customer?.City ? (
              <Typography sx={styles.Typography}>
                {`${customer?.Street || ""} ${customer?.PostalCode || ""} ${
                  customer?.City || ""
                }`}
              </Typography>
            ) : (
              <Box sx={styles.missing}>
                <SMissing />
                <Typography sx={styles.Typography}>
                  Adresse non renseignée
                </Typography>
              </Box>
            )}
            {customer?.Birthdate && formatBirthdate(customer?.Birthdate) ? (
              <Typography sx={styles.Typography}>
                {formatBirthdate(customer.Birthdate)}
              </Typography>
            ) : (
              <Box sx={styles.missing}>
                <SMissing />
                <Typography sx={styles.Typography}>
                  Date de naissance non renseignée
                </Typography>
              </Box>
            )}
            {customer?.Phonenumber ? (
              <Typography sx={styles.Typography}>
                {customer.Phonenumber}
              </Typography>
            ) : (
              <Box sx={styles.missing}>
                <SMissing />
                <Typography sx={styles.Typography}>
                  Téléphone non renseigné
                </Typography>
              </Box>
            )}
            <Typography mt={2} sx={styles.Typography}>
              Niveau : {customer?.LastQualification}
            </Typography>
            {isAdmin() ? (
              <Typography sx={styles.Typography}>
                ID : {customer?.Id}
              </Typography>
            ) : null}
          </Box>

          <Box>
            <PhotoComponent
              customer={customer}
              setCustomerUpdated={setCustomerUpdated}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
