import React from "react";
import { Box, Typography, CircularProgress, Avatar } from "@mui/joy";
import Escapade from "../../styles/images/logo_escapade-long-plein_RVB_Blanc.svg";
import SaveIcon from "@mui/icons-material/Save";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { PortalResponse } from "../../api/auth/Auth.typed";
import PortalLoginModal from "./display/PortalLoginModal";
import dayjs from "dayjs";
import useAuth from "../../contexts/controllers/useAuth";
import Cookies from "js-cookie";
import { useAlert } from "../../hooks/useAlert";
import TrainingDocs from "./display/TrainingDocs";
import AddressComponent from "./display/AddressComponent";
import PhoneComponent from "./display/PhoneComponent";
import BirthdateComponent from "./display/BirthdayComponent";
import PhotoComponent from "./display/PhotoComponent";
import FileComponent from "./display/FileComponent";
import FileWithPDFComponent from "./display/FileWithPDFComponent";
import TrainingTest from "./display/TrainingTest";
import GdprComponent from "./display/GdprComponent";
import Chrome from "../../styles/images/Google Chrome Black.svg";

export default function PortalPage() {
  const [customer, setCustomer] = React.useState<PortalResponse>();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [street, setStreet] = React.useState<string | undefined>("");
  const [postalCode, setPostalCode] = React.useState<string | undefined>("");
  const [city, setCity] = React.useState<string | undefined>("");
  const [photo, setPhoto] = React.useState<
    string | Blob | File | ProgressEvent<FileReader>
  >();
  const [medical, setMedical] = React.useState<File | string | undefined>();
  const [disclaimer, setDisclaimer] = React.useState<File | string | undefined>();
  const [agreement, setAgreement] = React.useState<File | string | undefined>();
  const [phonenumber, setPhonenumber] = React.useState<string>("");
  const [birthdate, setBirthdate] = React.useState<string | undefined | null>(
    null
  );
  const [gdpr, setGdpr] = React.useState<boolean>(false);
  const { updatePortalCustomerInfo } = useAuth();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    if (customer) {
      setStreet(customer.Street);
      setPostalCode(customer.PostalCode);
      setCity(customer.City);
      setBirthdate(customer?.Birthdate);
      setPhonenumber(customer.Phonenumber);
      setPhoto(customer.Photo);
      setMedical(customer.MedicalCertificate);
      setAgreement(customer.MedicalAgreement);
      setDisclaimer(customer.MedicalDisclaimer);
      setGdpr(customer.GdprConsent);
    }
  }, [customer]);

  const handleSave = async () => {
    setIsLoading(true);

    const authToken = Cookies.get("authToken");

    try {
      if (authToken) {
        const formData = new FormData();

        // Ajouter les champs textuels
        formData.append("Id", String(customer?.Id || ""));
        formData.append("Street", street || "");
        formData.append("PostalCode", postalCode || "");
        formData.append("City", city || "");
        formData.append(
          "Birthdate",
          birthdate
            ? dayjs(birthdate).format("YYYY-MM-DD") + "T00:00:00Z"
            : dayjs("1900-01-01").format("YYYY-MM-DD") + "T00:00:00Z"
        );
        formData.append("Phonenumber", phonenumber || "");
        formData.append("GdprConsent", String(gdpr));
        
        if (photo !== undefined) {
          formData.append("Photo", String(photo));
        }

        if (medical instanceof File) {
          formData.append("MedicalCertificate", medical);
        }
        if (agreement instanceof File) {
          formData.append("MedicalAgreement", agreement);
        }
        if (disclaimer instanceof File) {
          formData.append("MedicalDisclaimer", disclaimer);
        }

        await updatePortalCustomerInfo(formData, authToken)
          .then(() => {
            showAlert("Informations envoyées avec succès !", "success");
          })
          .catch((err) => {
            setIsLoggedIn(false);
            showAlert("Session expirée", "danger");
          });
      } else {
        setIsLoggedIn(false);
        showAlert("Session expirée", "danger");
      }
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }

    setIsLoading(false);
  };

  const handleSignOff = () => {
    Cookies.remove("authToken");
    setIsLoggedIn(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
        color: "white",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img src={Escapade} width="300px" height="auto" alt="Logo" />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: 0,
          width: "100%",
          textAlign: "center",
          padding: "8px 0",
        }}
      >
        <Typography level="h4" fontSize={11} fontStyle={"italic"}>
          <span>©</span> powered by Zekay - 2024
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "4px",
          }}
        >
          <Typography level="h4" fontSize={11} fontStyle={"italic"}>
            Optimisé pour les navigateurs Chrome
          </Typography>
          <img
            src={Chrome}
            width="30px"
            alt="Logo Chrome"
            style={{
              filter: "invert(1)",
            }}
          />
        </Box>
      </Box>

      {isLoggedIn ? (
        <Box sx={{ position: "absolute", top: "160px" }} ml={5}>
          <Typography level="h4" fontSize="17px" gutterBottom>
            {"Bonjour " + customer?.FirstName + " !"}
          </Typography>
          <Typography level="h4" fontSize="14px" gutterBottom>
            {"Formation " + customer?.Agency + " - " + customer?.Qualification}
          </Typography>
        </Box>
      ) : null}

      {isLoggedIn ? (
        <Avatar
          sx={{
            bgcolor: "primary.main",
            width: 56,
            height: 56,
            cursor: "pointer",
            position: "absolute",
            top: 16,
            right: 16,
          }}
          onClick={handleSignOff}
        >
          <ExitToAppIcon color="primary" />
        </Avatar>
      ) : null}

      {!isLoggedIn ? (
        <PortalLoginModal
          setIsLoggedIn={setIsLoggedIn}
          setCustomer={setCustomer}
        />
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              top: "220px",
              left: 0,
              right: 0,
              bottom: "100px",
              overflowY: "auto",
              padding: 3,
            }}
          >
            {/* Sections */}
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Documents théoriques à consulter
              </Typography>
              <TrainingDocs
                course1={customer?.Course1Url}
                course2={customer?.Course2Url}
                theoryUrl={customer?.TheoryUrl}
              />
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Test en ligne
              </Typography>
              <TrainingTest testUrl={customer?.TestUrl} />
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Adresse
              </Typography>
              <AddressComponent
                street={street}
                postalCode={postalCode}
                city={city}
                setStreet={setStreet}
                setPostalCode={setPostalCode}
                setCity={setCity}
              ></AddressComponent>
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Téléphone
              </Typography>
              <PhoneComponent
                phonenumber={phonenumber}
                setPhonenumber={setPhonenumber}
              />
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Date de naissance
              </Typography>
              <BirthdateComponent
                birthdate={birthdate}
                setBirthdate={setBirthdate}
              />
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Photo
              </Typography>
              <PhotoComponent photo={photo} setPhoto={setPhoto} />
            </Box>
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Certificat médical
                <Typography fontSize={"12px"}> (PDF uniquement)</Typography>
              </Typography>
              <FileComponent
                file={medical}
                setFile={setMedical}
                title={"Certificat médical"}
              />
            </Box>
            {customer?.HasSdiTraining ? (
              <>
                <Box margin={1}>
                  <Typography level="h4" fontSize="16px" gutterBottom>
                    Décharge de responsabilité SDI
                  </Typography>
                  <FileWithPDFComponent
                    file={disclaimer}
                    setFile={setDisclaimer}
                    title="Décharge de responsabilité"
                    pdf={customer?.MedicalDisclaimerTemplate}
                  />
                </Box>
                <Box margin={1}>
                  <Typography level="h4" fontSize="16px" gutterBottom>
                    Décharge médicale SDI
                  </Typography>
                  <FileWithPDFComponent
                    file={agreement}
                    setFile={setAgreement}
                    title="Décharge médicale"
                    pdf={customer?.MedicalAgreementTemplate}
                  />
                </Box>
              </>
            ) : null}
            <Box margin={1}>
              <Typography level="h4" fontSize="16px" gutterBottom>
                Préferences de communication
              </Typography>
              <GdprComponent gdpr={gdpr} setGdpr={setGdpr} />
            </Box>
          </Box>

          {/* Fixed Save Button */}
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "100px",
              backgroundColor: "black",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "primary.main",
                width: 56,
                height: 56,
                cursor: "pointer",
              }}
              onClick={handleSave}
            >
              {!isLoading ? (
                <SaveIcon color="primary" />
              ) : (
                <CircularProgress sx={{ color: "white" }} />
              )}
            </Avatar>
          </Box>
        </>
      )}
    </Box>
  );
}
