import React, { Dispatch, SetStateAction } from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/joy";
import VisibilityIcon from "@mui/icons-material/Visibility";

type FileComponentProps = {
  file: File | string | undefined; // Le fichier peut être une URL ou un fichier brut
  setFile: Dispatch<SetStateAction<File | string | undefined>>; // Setter pour transmettre le fichier
  title: string | undefined;
};

export default function FileComponent({
  file,
  setFile,
  title,
}: FileComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        alert("Seuls les fichiers PDF sont acceptés.");
        setIsLoading(false);
        return;
      }
      setFile(selectedFile); // Met à jour avec le fichier brut
    }
    setIsLoading(false);
  };

  const viewFile = () => {
    if (typeof file === "string") {
      // Si le fichier est une URL, ouvrir dans un nouvel onglet
      window.open(file, "_blank", "noopener,noreferrer");
    } else if (file instanceof File) {
      // Si le fichier est brut, créer une URL temporaire
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {!isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              {/* Upload file */}
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={uploadFile}
                  accept="application/pdf"
                />
                <Tooltip title="Ajouter document">
                  <IconButton component="span" size="lg" color="primary">
                    <Avatar
                      variant="soft"
                      size="sm"
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    >
                      +
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </label>

              {/* View file */}
              {file && (
                <Tooltip title="Voir le document">
                  <IconButton onClick={viewFile} size="lg" color="primary">
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            {/* File status */}
            {file ? (
              <Box display="flex" alignItems="center" gap={1}>
                <Typography fontSize="sm" color="success">
                  Document renseigné
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap={1}>
                <Typography fontSize="sm" color="danger">
                  Document non renseigné
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <CircularProgress size="lg" />
        )}
      </Box>
    </Box>
  );
}
