import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/joy";
import DocumentTypeComponent from "./display/DocumentTypeComponent";
import useZekay from "../../contexts/controllers/useZekay";
import { File, Training } from "../../api/zekay/Zekay.typed";
import PdfComponent from "./PdfComponent";
import { SFile } from "../../styles/StyledMUI";
import SheetTypeComponent from "./display/SheetTypeComponent";

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

interface DocumentsComponentProps {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings: RefreshTrainingFunction;
  isOpenDocuments: boolean;
}

export default function DocumentsComponent(props: DocumentsComponentProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<string | undefined>();
  const { updateTrainingFile, deleteTrainingFile } = useZekay();

  const uploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      alert("Aucun fichier sélectionné");
      return;
    }

    // Préparer FormData
    const formData = new FormData();
    formData.append("CustomerID", String(props.training.CustomerID));
    formData.append("CreditID", String(props.training.CreditId));
    formData.append("Type", type);
    formData.append("File", file);

    // Envoyer le fichier à l'API
    await updateTrainingFile(formData);

    // Rafraîchir la liste des formations après l'upload
    const updatedTrainings = await props.refreshTrainings();
    props.setTrainings(updatedTrainings);
  };

  const deleteFile = async (type: string) => {
    const f: File = {
      CustomerID: props.training.CustomerID,
      CreditID: props.training.CreditId,
      Type: type,
    };

    await deleteTrainingFile(f);
    const updatedTrainings = await props.refreshTrainings();
    props.setTrainings(updatedTrainings);
  };

  const handleView = (type: string) => {
    switch (type) {
      case "sheet":
        setDocument(props.training?.TrainingSheet);
        break;
      case "agreement":
        setDocument(props.training?.MedicalAgreement);
        break;
      case "disclaimer":
        setDocument(props.training?.MedicalDisclaimer);
        break;
      case "medical":
        setDocument(props.training?.MedicalCertificate);
        break;
    }
    setIsOpen(true);
  };

  return (
    <tr>
      <td colSpan={12} style={{ border: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          <SFile />
          <Box sx={{ marginLeft: "10px", display: "flex", gap: 2 }}>
            <SheetTypeComponent
              document={props.training?.TrainingSheet}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              handleView={handleView}
              documentType="sheet"
              training={props.training}
              refreshTrainings={props.refreshTrainings}
              setTrainings={props.setTrainings}
            />
            {props.training.IsSDI && (
              <>
                <DocumentTypeComponent
                  document={props.training?.MedicalDisclaimer}
                  uploadFile={uploadFile}
                  deleteFile={deleteFile}
                  handleView={handleView}
                  title="Décharge de responsabilité"
                  documentType="disclaimer"
                />
                <DocumentTypeComponent
                  document={props.training?.MedicalAgreement}
                  uploadFile={uploadFile}
                  deleteFile={deleteFile}
                  handleView={handleView}
                  title="Décharge médicale"
                  documentType="agreement"
                />
              </>
            )}
            <DocumentTypeComponent
              document={props.training?.MedicalCertificate}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              handleView={handleView}
              title="Certificat médical"
              documentType="medical"
            />
          </Box>
          {isOpen && (
            <Box mt={2}>
              <PdfComponent file={document as string} setIsOpen={setIsOpen} />
            </Box>
          )}
        </Box>
      </td>
    </tr>
  );
}
