import { Tooltip, Typography } from "@mui/joy";
import { Training } from "../../api/zekay/Zekay.typed";

import { SInfo } from "../../styles/StyledMUI";
import { formatDate } from "../../utils/dateTime";

interface DetailsTooltipProps {
  training: Training;
}


export default function DetailsTooltip(props: DetailsTooltipProps) {
  return (
    <Tooltip
      sx={{ backgroundColor: "#171717" }}
      title={
        <>
          <Typography level="h4">
            Créée à : {formatDate(props.training.CreatedAt)}
          </Typography>
          <Typography level="h4">
            Mise à jour à : {formatDate(props.training.UpdatedAt)}
          </Typography>
          <Typography level="h4">
            Mise à jour par : {props.training.UpdatedBy || "N/A"}
          </Typography>
        </>
      }
    >
      <SInfo sx={{ color: "darkgrey" }} />
    </Tooltip>
  );
}