import { Box, IconButton } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SReset } from "../../../styles/StyledMUI";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type BirthdateComponentProps = {
  birthdate: string | null | undefined; // API renvoie une chaîne de caractères (format ISO 8601 ou "0001-01-01T00:00:00Z")
  setBirthdate: Dispatch<SetStateAction<string | null | undefined>>; // Setter pour la date
};

export default function BirthdateComponent(props: BirthdateComponentProps) {
  const maxDate = dayjs().format("YYYY-MM-DD"); // Limite maximale : aujourd'hui
  const minDate = "1900-01-01"; // Limite minimale : une date réaliste pour les utilisateurs

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value; // Valeur au format ISO (YYYY-MM-DD)
    if (newDate) {
      props.setBirthdate(`${newDate}T00:00:00Z`); // Ajouter l'heure et le fuseau horaire pour respecter le format API
    } else {
      props.setBirthdate(null); // Si aucune date, réinitialiser à null
    }
  };

  const handleResetDate = () => {
    props.setBirthdate(null); // Réinitialiser à une valeur vide
  };

  const formatInputValue = (date: string | null | undefined): string => {
    if (!date || date === "0001-01-01T00:00:00Z") {
      return ""; // Si la date est vide ou égale à "0001-01-01T00:00:00Z", ne rien afficher
    }
    return dayjs(date).format("YYYY-MM-DD"); // Convertir en format ISO pour affichage dans l'input
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {/* Input de date */}
        <Box
          component="input"
          type="date"
          value={formatInputValue(props.birthdate)} // Formater pour affichage
          onChange={handleDateChange}
          max={maxDate} // Empêcher la sélection de dates futures
          min={minDate} // Empêcher les dates antérieures à 1900
          sx={{
            width: "100%",
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "neutral.outlinedBorder",
            backgroundColor: "white",
          }}
        />
        {/* Bouton pour réinitialiser */}
        {props.birthdate && props.birthdate !== "0001-01-01T00:00:00Z" && (
          <IconButton onClick={handleResetDate} sx={{ fontSize: "12px" }}>
            <SReset sx={{ color: "red" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
