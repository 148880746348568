import React, { Dispatch, SetStateAction, useRef } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/joy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";

type FileWithPDFComponentProps = {
  file: File | string | undefined; // Le fichier peut être une URL ou un fichier brut
  setFile: Dispatch<SetStateAction<File | string | undefined>>;
  title: string | undefined;
  pdf: string | undefined; // URL du document original fourni par l'API
};

const FileWithPDFComponent: React.FC<FileWithPDFComponentProps> = ({
  file,
  setFile,
  title,
  pdf,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        alert("Seuls les fichiers PDF sont acceptés.");
        setIsLoading(false);
        return;
      }
      setFile(selectedFile); // Enregistre le fichier brut dans l'état
    }
    setIsLoading(false);
  };

  const viewFile = () => {
    if (typeof file === "string") {
      // Si le fichier est une URL, ouvrir directement l'URL
      window.open(file, "_blank", "noopener,noreferrer");
    } else if (file instanceof File) {
      // Si le fichier est brut, créer une URL temporaire
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank", "noopener,noreferrer");
    }
  };

  const handleIconButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      {/* Section pour afficher le document original */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          flex: 1,
        }}
      >
        {pdf ? (
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Tooltip title="Voir le document original">
              <IconButton color="primary" size="lg">
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
            <Typography fontSize="sm">Document original</Typography>
            <Typography fontSize="sm">
              (à télécharger, remplir et redéposer dans la zone de droite)
            </Typography>
          </a>
        ) : null}
      </Box>

      <Divider orientation="vertical" sx={{ marginX: 2 }} />

      {/* Section pour uploader et visualiser le fichier */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {!isLoading ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box display="flex" gap={2}>
              {/* Input pour uploader le fichier */}
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
                accept="application/pdf"
              />
              <Tooltip title="Ajouter document">
                <IconButton
                  color="primary"
                  size="lg"
                  onClick={handleIconButtonClick}
                >
                  +
                </IconButton>
              </Tooltip>

              {/* Bouton pour visualiser le fichier */}
              {file && (
                <Tooltip title="Voir le document ajouté">
                  <IconButton color="primary" size="lg" onClick={viewFile}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            {/* État du fichier */}
            <Box>
              {file ? (
                <Typography fontSize="sm" color="success">
                  Document renseigné
                </Typography>
              ) : (
                <Typography fontSize="sm" color="danger">
                  Document non renseigné
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default FileWithPDFComponent;
