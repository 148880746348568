import React, { ReactNode } from "react";
import {
  ActionLog,
  CourseTemplate,
  ExcursionsStats,
  File,
  InvoicesDate,
  Mail,
  Session,
  StatDate,
  StatsHandler,
  SystemInfos,
  Training,
  TrainingStats,
  TrainingTemplate,
  UpdateLog,
  WorkerLog,
  ZekayConfig,
  ZekayCustomer,
} from "../api/zekay/Zekay.typed";
import { AppConfigContext } from "./AppConfigContext";
import { ZekayModel } from "../api/zekay/ZekayModel";
import { useAlert } from "../hooks/useAlert";
import { AuthContext } from "./AuthContext";
import { ZekayAdapter } from "../api/zekay/ZekayAdapter";
import { useConfirmationModal } from "../hooks/useConfirmationModal";

interface ZekayProviderProps {
  children: ReactNode;
}

interface ZekayContextType {
  zekayClient: ZekayAdapter;
  refreshCustomers: () => void;
  customers?: ZekayCustomer[];
  refreshZuuritCustomers: () => void;
  refreshTrainingTemplates: () => void;
  qualifications?: TrainingTemplate[];
  getTrainingsTemplates: () => Promise<TrainingTemplate[] | undefined>;
  updateTrainingTemplate: (form: TrainingTemplate) => Promise<void>;
  getCustomers: () => Promise<ZekayCustomer[] | undefined>;
  getCustomer: (id: string) => Promise<ZekayCustomer | undefined>;
  getDoneTrainings: () => Promise<Training[] | undefined>;
  getProductsCsv: () => Promise<string | undefined>;
  getInvoicesCsv: (period: InvoicesDate) => Promise<string | undefined>;
  createTraining: (form: Training) => Promise<void>;
  deleteTraining: (form: Training) => Promise<void>;
  validateUserQualification: (form: Training) => Promise<void>;
  updateFile: (form: File) => Promise<void>;
  updateTrainingFile: (formData: FormData) => Promise<void>;
  deleteTrainingFile: (form: File) => Promise<void>;
  getStatDates: () => Promise<StatDate[] | undefined>;
  getExcursionsStatsByYear: (
    date: StatsHandler
  ) => Promise<ExcursionsStats[] | undefined>;
  getExcursionsStatsByMonth: (
    date: StatsHandler
  ) => Promise<ExcursionsStats | undefined>;
  getTrainingStatsByYear: (
    date: StatsHandler
  ) => Promise<TrainingStats[] | undefined>;
  getTrainingsByCreditId: (id: string) => Promise<Training[] | undefined>;
  getTrainingsByStatusCreditId: (
    id: string,
    status: number
  ) => Promise<Training[] | undefined>;
  getTrainingByUserCreditId: (
    creditId: number,
    userId: number
  ) => Promise<Training | undefined>;
  sendMail: (form: Mail) => Promise<void>;
  updateTraining: (form: Training) => Promise<void>;
  cancelTraining: (form: Training) => Promise<void>;
  getLogsByTable: (table: string) => Promise<UpdateLog | undefined>;
  getActionsLogs: () => Promise<ActionLog[] | undefined>;
  getWorkerLogs: () => Promise<WorkerLog[] | undefined>;
  getWorkerLogsByUserId: (id: string) => Promise<WorkerLog[] | undefined>;
  getWorkerLogsByStatus: (status: string) => Promise<WorkerLog[] | undefined>;
  getCourseTemplate: (id: number) => Promise<CourseTemplate | undefined>;
  createCourseTemplate: (ct: CourseTemplate) => Promise<void>;
  updateCourseTemplate: (ct: CourseTemplate) => Promise<void>;
  createSession: (form: Session) => Promise<void>;
  updateSession: (session: Session) => Promise<void>;
  generateSessionPdf: (customerId: number, creditId: number) => Promise<void>;
  getConfig: (id: number) => Promise<ZekayConfig | undefined>;
  updateConfig: (cfg: ZekayConfig) => Promise<void>;
  getSystemInfos: () => Promise<SystemInfos | undefined>;
  getGoogleLabels: () => Promise<string[] | undefined>;
  getQualificationsList: () => Promise<string[] | undefined>;
  getNewsletterMapping: () => Promise<{ [key: string]: string[] } | undefined>;
  updateNewsletterMapping: (mapping: {
    [key: string]: string[];
  }) => Promise<void>;
}

export const ZekayContext = React.createContext<ZekayContextType>(null!);

export function ZekayProvider(props: ZekayProviderProps) {
  const appConfig = React.useContext(AppConfigContext);
  const { showAlert } = useAlert();

  const zekayModel = new ZekayModel(appConfig!);
  const zekayClient = new ZekayAdapter(zekayModel);

  const { isLogged } = React.useContext(AuthContext);
  const modalContext = useConfirmationModal();

  const [customers, setCustomers] = React.useState<ZekayCustomer[]>();
  const [qualifications, setQualifications] =
    React.useState<TrainingTemplate[]>();

  React.useEffect(() => {
    if (isLogged) {
      refreshCustomers();
      refreshTrainingTemplates();
    }
  }, [isLogged]);

  const refreshCustomers = async () => {
    try {
      await zekayClient
        .getCustomers()
        .then((Customers: ZekayCustomer[]) => setCustomers(Customers));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const refreshZuuritCustomers = async () => {
    try {
      await zekayClient.refreshZuurit();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const refreshTrainingTemplates = async () => {
    try {
      await zekayClient
        .getTrainingsTemplates()
        .then((q) => setQualifications(q));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getDoneTrainings = async (): Promise<Training[] | undefined> => {
    try {
      return await zekayClient.getDoneTrainings();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getTrainingsByCreditId = async (
    id: string
  ): Promise<Training[] | undefined> => {
    try {
      return await zekayClient.getTrainingsByCreditId(id);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getTrainingsByStatusCreditId = async (
    id: string,
    status: number
  ): Promise<Training[] | undefined> => {
    try {
      return await zekayClient.getTrainingsByStatusCreditId(id, status);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getTrainingByUserCreditId = async (
    creditId: number,
    userId: number
  ): Promise<Training | undefined> => {
    try {
      return await zekayClient.getTrainingByUserCreditId(creditId, userId);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getTrainingsTemplates = async (): Promise<
    TrainingTemplate[] | undefined
  > => {
    try {
      return await zekayClient.getTrainingsTemplates();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateTrainingTemplate = async (
    form: TrainingTemplate
  ): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous modifier cette formation ?"
    );
    if (confirm) {
      try {
        await zekayClient
          .updateTrainingTemplate(form)
          .then(() => showAlert("Modifications enregistrées !", "success"));
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const getCustomer = async (
    id: string
  ): Promise<ZekayCustomer | undefined> => {
    try {
      return await zekayClient.getCustomer(id);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getCustomers = async (): Promise<ZekayCustomer[] | undefined> => {
    try {
      return await zekayClient.getCustomers();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getProductsCsv = async (): Promise<string | undefined> => {
    try {
      return await zekayClient.getProductsCsv();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getInvoicesCsv = async (
    period: InvoicesDate
  ): Promise<string | undefined> => {
    try {
      return await zekayClient.getInvoicesCsv(period);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const createTraining = async (form: Training): Promise<void> => {
    try {
      await zekayClient.createTraining(form);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const deleteTraining = async (form: Training): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous supprimer cette formation ?\nLe compte de crédit associé dans Zuurit doit également être supprimé."
    );

    if (confirm) {
      try {
        await zekayClient
          .deleteTraining(form)
          .then(() => showAlert("Formation supprimée !", "success"));
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const validateUserQualification = async (form: Training): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous valider cette formation ?\n Le profil Zuurit du plongeur sera mis à jour."
    );

    if (confirm) {
      try {
        await zekayClient
          .validateUserQualification(form)
          .then(() => showAlert("Formation validée", "success"));
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const updateTraining = async (form: Training): Promise<void> => {
    try {
      await zekayClient
        .updateTraining(form)
        .then(() => showAlert("Modifications enregistrées !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const cancelTraining = async (form: Training): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous annuler cette formation ?"
    );
    if (confirm) {
      try {
        await zekayClient
          .updateTraining(form)
          .then(() => showAlert("Modifications enregistrées !", "success"));
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const updateFile = async (form: File): Promise<void> => {
    try {
      await zekayClient.updateFile(form);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateTrainingFile = async (formData: FormData): Promise<void> => {
    try {
      await zekayClient.updateTrainingFile(formData);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const deleteTrainingFile = async (file: File): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous supprimer ce document ?"
    );
    if (confirm) {
      try {
        await zekayClient.deleteTrainingFile(file);
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const getStatDates = async (): Promise<StatDate[] | undefined> => {
    try {
      return await zekayClient.getStatDates();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getExcursionsStatsByYear = async (
    date: StatsHandler
  ): Promise<ExcursionsStats[] | undefined> => {
    try {
      return await zekayClient.getExcursionsStatsByYear(date);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getExcursionsStatsByMonth = async (
    date: StatsHandler
  ): Promise<ExcursionsStats | undefined> => {
    try {
      return await zekayClient.getExcursionsStatsByMonth(date);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getTrainingStatsByYear = async (
    date: StatsHandler
  ): Promise<TrainingStats[] | undefined> => {
    try {
      return await zekayClient.getTrainingStatsByYear(date);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const sendMail = async (form: Mail): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous envoyer les informations de formation ?"
    );

    if (confirm) {
      try {
        await zekayClient.sendMail(form);
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const getLogsByTable = async (
    table: string
  ): Promise<UpdateLog | undefined> => {
    try {
      return await zekayClient.getLogsByTable(table);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getActionsLogs = async (): Promise<ActionLog[] | undefined> => {
    try {
      return await zekayClient.getActionsLogs();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getWorkerLogs = async (): Promise<WorkerLog[] | undefined> => {
    try {
      return await zekayClient.getWorkerLogs();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getWorkerLogsByUserId = async (
    id: string
  ): Promise<WorkerLog[] | undefined> => {
    try {
      return await zekayClient.getWorkerLogsByUserId(id);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getWorkerLogsByStatus = async (
    status: string
  ): Promise<WorkerLog[] | undefined> => {
    try {
      return await zekayClient.getWorkerLogsByStatus(status);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getCourseTemplate = async (
    id: number
  ): Promise<CourseTemplate | undefined> => {
    try {
      return await zekayClient.getCourseTemplate(id);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const createCourseTemplate = async (ct: CourseTemplate): Promise<void> => {
    try {
      await zekayClient
        .createCourseTemplate(ct)
        .then(() => showAlert("Modèle créé !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateCourseTemplate = async (ct: CourseTemplate): Promise<void> => {
    const confirm = await modalContext.showConfirmation(
      "Confirmation",
      "Voulez-vous modifier ce modèle ?"
    );
    if (confirm) {
      try {
        await zekayClient
          .updateCourseTemplate(ct)
          .then(() => showAlert("Modifications enregistrées !", "success"));
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    }
  };

  const createSession = async (form: Session): Promise<void> => {
    try {
      await zekayClient.createSession(form);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateSession = async (session: Session): Promise<void> => {
    try {
      await zekayClient
        .updateSession(session)
        .then(() => showAlert("Modifications enregistrées !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const generateSessionPdf = async (
    customerId: number,
    creditId: number
  ): Promise<void> => {
    try {
      await zekayClient
        .generateSessionPDF(creditId, customerId)
        .then(() => showAlert("PDF généré !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getConfig = async (id: number): Promise<ZekayConfig | undefined> => {
    try {
      return await zekayClient.getConfig(id);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateConfig = async (cfg: ZekayConfig): Promise<void> => {
    try {
      await zekayClient
        .updateConfig(cfg)
        .then(() => showAlert("Modifications enregistrées !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getSystemInfos = async (): Promise<SystemInfos | undefined> => {
    try {
      return await zekayClient.getSystemInfos();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getGoogleLabels = async (): Promise<string[] | undefined> => {
    try {
      return await zekayClient.getGoogleLabels();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getQualificationsList = async (): Promise<string[] | undefined> => {
    try {
      return await zekayClient.getQualificationsList();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getNewsletterMapping = async (): Promise<
    { [key: string]: string[] } | undefined
  > => {
    try {
      return await zekayClient.getNewsletterMapping();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const updateNewsletterMapping = async (mapping: {
    [key: string]: string[];
  }): Promise<void> => {
    try {
      return await zekayClient.updateNewsletterMapping(mapping);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const contextValue = {
    zekayClient,
    refreshCustomers,
    customers,
    refreshZuuritCustomers,
    refreshTrainingTemplates,
    qualifications,
    getTrainingsTemplates,
    getCustomers,
    getCustomer,
    getDoneTrainings,
    getProductsCsv,
    getInvoicesCsv,
    createTraining,
    deleteTraining,
    validateUserQualification,
    updateFile,
    updateTrainingFile,
    deleteTrainingFile,
    getStatDates,
    getExcursionsStatsByYear,
    getExcursionsStatsByMonth,
    getTrainingsByCreditId,
    getTrainingsByStatusCreditId,
    sendMail,
    updateTraining,
    cancelTraining,
    getLogsByTable,
    getActionsLogs,
    getWorkerLogs,
    getWorkerLogsByUserId,
    getCourseTemplate,
    createCourseTemplate,
    updateCourseTemplate,
    createSession,
    updateSession,
    generateSessionPdf,
    updateTrainingTemplate,
    getConfig,
    updateConfig,
    getSystemInfos,
    getGoogleLabels,
    getQualificationsList,
    getNewsletterMapping,
    updateNewsletterMapping,
    getWorkerLogsByStatus,
    getTrainingStatsByYear,
    getTrainingByUserCreditId,
  };

  return (
    <ZekayContext.Provider value={contextValue}>
      {props.children}
    </ZekayContext.Provider>
  );
}
